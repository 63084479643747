import moment from 'moment'

const masks = {
    formataCelular(celular) {
        if (celular != undefined) {
            celular = celular.replace(/\D/g,'')
            celular = celular.replace(/(\d{2})(\d)/,"($1) $2")
            celular = celular.replace(/(\d)(\d{4})$/,"$1-$2")
            return celular;
        }
    },

    formataCPF(cpf) {
        if (cpf != undefined) {
            cpf = cpf.replace(/[^\d]/g, "");
            return (cpf) ? cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4") : '';
        }
    },

    formataCNPJ(cnpj) {
        if (cnpj != undefined) {
            cnpj = cnpj.replace(/[^\d]/g, "");
            cnpj = masks.addZeroEsquerda(cnpj, 14)
            return (cnpj) ? cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5") : '';
        }
    },

    formataCEP(cep) {
        cep = cep.replace(/[^\d]/g, "");
        return (cep) ? cep.replace(/(\d{5})(\d{3})/, "$1-$2") : '';
    },

    // Função criada para acrescentar os zeros a esquerda quando o back enviar o cnpj
    addZeroEsquerda(num, len) {
        var numberWithZeroes = String(num);
        var counter = numberWithZeroes.length;

        while (counter < len) {
            numberWithZeroes = "0" + numberWithZeroes;
            counter++;
        }

        return numberWithZeroes;
    },

    // A aplicação usa dois tipos de formatos
    // YYYY-MM-DD este é normalmente o formato enviado para o back 
    // DD/MM/YYYY este é normalmente usado no front 
    formatarData(data, format = "DD/MM/YYYY") {
        return moment(data).format(format);
    }
}

export default masks