import axios from 'axios';
import store from '@/store';
import { _rotasAPI } from '@/services/_caminhosApi';
import { tokenExpirado } from '@/utils/tokenExpirado';
//

export function listarTreinamento(data) {
  return new Promise((resolve) => {
    const token = localStorage.getItem('token');
    store.commit('SET_ADICIONAR_LOADING');
    axios
      .post(`${_rotasAPI().listarTreinamento}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch((erro) => {
        tokenExpirado(erro, true);
      })
      .finally(() => store.commit('SET_REMOVER_LOADING'));
  });
}

export function detalhesBannerTreinamento(id) {
  return new Promise((resolve) => {
    const token = localStorage.getItem('token');
    store.commit('SET_ADICIONAR_LOADING');
    axios
      .get(`${_rotasAPI().ObterTreinamento}${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch((erro) => {
        tokenExpirado(erro, true);
      })
      .finally(() => store.commit('SET_REMOVER_LOADING'));
  });
}

export function listarTipoTreinamento() {
  return new Promise((resolve) => {
    const token = localStorage.getItem('token');
    store.commit('SET_ADICIONAR_LOADING');
    axios
      .get(`${_rotasAPI().listarTipoTreinamento}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch((erro) => {
        tokenExpirado(erro, true);
      })
      .finally(() => store.commit('SET_REMOVER_LOADING'));
  });
}

export function listarNomeTreinamento() {
  return new Promise((resolve) => {
    const token = localStorage.getItem('token');
    store.commit('SET_ADICIONAR_LOADING');
    axios
      .get(`${_rotasAPI().listarNomeTreinamento}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch((erro) => {
        tokenExpirado(erro, true);
      })
      .finally(() => store.commit('SET_REMOVER_LOADING'));
  });
}

export function cadastrarTreinamento(data) {
  return new Promise((resolve) => {
    const token = localStorage.getItem('token');
    store.commit('SET_ADICIONAR_LOADING');
    axios
      .post(_rotasAPI().cadastrarTreinamento, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        resolve(resp);
        store.commit("SET_MODAL", {
          ativado: true,
          mensagem: resp.data.mensagem,
          tipo: "sucesso",
        })
      })
      .catch((erro) => {
        tokenExpirado(erro, erro.response.data);
      })
      .finally(() => store.commit('SET_REMOVER_LOADING'));
  });
}

export function atualizarTreinamento(data) {
  return new Promise((resolve) => {
    const token = localStorage.getItem('token');
    store.commit('SET_ADICIONAR_LOADING');
    axios
      .put(_rotasAPI().atualizarTreinamento, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        resolve(resp);
        store.commit("SET_MODAL", {
          ativado: true,
          mensagem: resp.data.mensagem,
          tipo: "sucesso",
        })
      })
      .catch((erro) => {
        tokenExpirado(erro, erro.response.data);
      })
      .finally(() => store.commit('SET_REMOVER_LOADING'));
  });
}