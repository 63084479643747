<template>
	<form @submit.prevent="salvar" class="formulario-container">
		<div class="formulario-linha linha-tripla">
			<div class="formulario-item medio">
				<label class="formulario-item-label">Tipo de Autorizada</label>
				<multiselect label="descricao" track-by="descricao" v-model="form.autorizada" :options="tipo"
					:searchable="false" :allow-empty="false" placeholder="" select-label="Selecionar" deselect-label=" "
					selected-label="Selecionado" @input="limpaFiltros()" :class="{
						'invalid-field-multiselect':
							showErrors && $v.form.autorizada.$invalid,
					}" />

				<template slot="singleLabel" slot-scope="{}"><strong v-if="listaIdsAutorizadas.length">{{
					listaIdsAutorizadas.length }} {{ form.autorizada.descricao }}(s)
						selecionada(s)</strong></template>
				<!-- <span slot="noResult">Item não encontrado</span> -->
			</div>

			<div class="formulario-item medio" v-if="form.autorizada.id == 1 || form.autorizada.id == 4">
				<label class="formulario-item-label">Assistência</label>
				<multiselect ref="assistencia" label="descricao" track-by="descricao" v-model="filtros.assistencias"
					:options="assistencias" :searchable="true" :allow-empty="false"
					placeholder="Seleciona a(s) assistência(s)" select-label="Selecionar" deselect-label=" "
					selected-label="Selecionado" @input="assistenciaSelecionado('assistencia')" />
			</div>

			<div class="formulario-item medio" v-if="form.autorizada.id == 3 || form.autorizada.id == 4">
				<label class="formulario-item-label">Franquia</label>
				<multiselect ref="franquia" label="descricao" track-by="descricao" v-model="filtros.franquias"
					:options="franquias" :searchable="true" :allow-empty="false" placeholder="Seleciona a(s) franquia(s)"
					select-label="Selecionar" deselect-label=" " selected-label="Selecionado"
					@input="assistenciaSelecionado('franquia')" />
			</div>

			<div class="formulario-item medio">
				<label class="formulario-item-label">Grupo</label>
				<multiselect ref="assistencia" label="descricao" track-by="descricao" v-model="form.grupoAutorizadas"
					:options="comboGrupo" :searchable="true" :allow-empty="false"
					placeholder="Seleciona a(s) assistência(s)" select-label="Selecionar" deselect-label=" "
					selected-label="Selecionado" @input="listaAutorizadaGrupo(), limpaFiltros()" />
			</div>

			<div id="box-filtros-assistencia" v-if="filtrosLista.assistencias.length > 0" class="box-filtros">
				<div class="box-btn-titulo">
					<h2 class="titulo-filtro">Assistência(s) selecionada(s)</h2>
					<p class="btn-limpar-selecao" @click="
						(filtrosLista.assistencias = []), (filtros.assistencias = [])
						">
						Limpar Assistências
					</p>
				</div>
				<div v-if="filtrosLista.assistencias.length > 0"
					class="tabela-informacoes franquias-assistencias-selecionadas">
					<div class="box-colunas-assistencia tabela-linha tabela-linha-informacoes quebra-linha">
						<div v-for="(assistencia, index) in filtrosLista.assistencias" :key="index"
							class="coluna-nome-assistencia">
							<p>{{ assistencia.descricao }}</p>
							<button @click.stop.prevent
								@click="filtrosLista.assistencias.splice(filtrosLista.assistencias.indexOf(assistencia), 1)"
								class="btn-excluir-assistencia">
								X
							</button>
						</div>
					</div>
				</div>
			</div>

			<div id="box-filtros-franquia" v-if="filtrosLista.franquias.length > 0" class="box-filtros">
				<div class="box-btn-titulo">
					<h2 class="titulo-filtro">Franquia(s) selecionada(s)</h2>
					<p class="btn-limpar-selecao" @click="(filtrosLista.franquias = []), (filtros.franquias = [])">
						Limpar Franquias
					</p>
				</div>
				<div v-if="filtrosLista.franquias.length > 0"
					class="tabela-informacoes franquias-assistencias-selecionadas">
					<div class="box-colunas-assistencia tabela-linha tabela-linha-informacoes quebra-linha">
						<div v-for="(franquia, index) in filtrosLista.franquias" :key="index"
							class="coluna-nome-assistencia">
							<p>{{ franquia.descricao }}</p>
							<button @click.stop.prevent
								@click="filtrosLista.franquias.splice(filtrosLista.franquias.indexOf(franquia), 1)"
								class="btn-excluir-assistencia">
								X
							</button>
						</div>
					</div>
				</div>
			</div>

			<div class="formulario-item">
				<label class="formulario-item-label">Tipo de treinamento</label>
				<multiselect v-model="form.tipoTreinamento" label="descricao" track-by="descricao"
					:options="tipoTreinamento" :searchable="false" :multiple="false" :allow-empty="false"
					@input="idsCargos = []" placeholder="Selecione" select-label="Selecionar" deselect-label=" "
					selected-label="Selecionado" :class="{
						'invalid-field-multiselect':
							showErrors && $v.form.tipoTreinamento.$invalid,
					}" />
				<p class="error input-erro-mensagem" v-if="showErrors && !$v.form.tipoTreinamento.required">
					Campo obrigatório!
				</p>
			</div>

			<div v-if="listaIdsAutorizadas.length" class="mb-3">
				<Box :titulo="form.autorizada.descricao" :lista.sync="listaIdsAutorizadas"
					:varSelect.sync="form.idAutorizadas" />
			</div>

			<div class="formulario-item">
				<label for="cargo" class="formulario-item-label">Cargo</label>

				<multiselect v-model="idsCargos" label="descricao" track-by="id" :options="cargosFiltrado" :multiple="true"
					placeholder="Selecione" select-label="Selecionar" deselect-label="Remover" selected-label="Selecionado"
					:allow-empty="false" :class="{
						'invalid-field-multiselect': showErrors && $v.idsCargos.$invalid,
					}" disabled />
				<p class="error input-erro-mensagem" v-if="showErrors && !$v.idsCargos.required">
					Campo obrigatório!
				</p>
			</div>



			<div class="formulario-item form-group">
				<label class="formulario-item-label form__label">Nome do treinamento</label>
				<input :class="{
					'invalid-field': showErrors && $v.form.nomeTreinamento.$invalid,
				}" class="form__input" type="text" v-model="form.nomeTreinamento" />
				<p class="error input-erro-mensagem" v-if="showErrors && !$v.form.nomeTreinamento.required">
					Campo obrigatório!
				</p>
				<p class="error input-erro-mensagem" v-if="showErrors && !$v.form.nomeTreinamento.minLength">
					O campo deverá possuir pelo menos
					{{ $v.form.nomeTreinamento.$params.minLength.min }} letras.
				</p>
			</div>

			<div class="formulario-item">
				<label for="dataInicio" class="formulario-item-label">Data inicio</label>
				<datepicker v-model="form.dataInicio" name="dataInicio" :language="ptBR" />
			</div>
			<div class="formulario-item">
				<label for="dataFim" class="formulario-item-label">Data final</label>
				<datepicker v-model="form.dataFim" name="dataFim" :language="ptBR" />
			</div>

			<div class="formulario-item">
				<label class="formulario-item-label">Link</label>
				<input :class="{ 'invalid-field': showErrors && $v.form.link.$invalid }" type="text"
					v-model.trim="form.link" />
				<p class="error input-erro-mensagem" v-if="showErrors && !$v.form.link.required">
					Campo obrigatório!
				</p>
				<p class="error input-erro-mensagem" v-if="showErrors && !$v.form.link.url">
					Apenas link é aceito
				</p>
			</div>

			<div class="formulario-item" v-if="editar">
				<label class="formulario-item-label">Ativo</label>
				<multiselect v-model="form.ativo" label="descricao" track-by="value" :options="opcoesAtivo"
					:searchable="false" :multiple="false" :preselect-first="false" :allow-empty="true"
					placeholder="Selecione" select-label="Selecionar" deselect-label="Remover" selected-label="Selecionado"
					:class="{
						'invalid-field-multiselect': showErrors && $v.form.ativo.$invalid,
					}" />
				<p class="error input-erro-mensagem" v-if="showErrors && !$v.form.ativo.required">
					Campo obrigatório!
				</p>
			</div>
		</div>

		<div class="formulario-linha linha-dupla">
			<div class="formulario-item container-upload">
				<label class="formulario-item-label">Imagem Desktop</label>
				<input id="input-imagem-desktop" type="file" @change="atualizarImagem('imagemDesktop', $event)" />
				<div class="upload-div" :style="imagemBgDesktop" :class="{ active: form.imagemDesktop }">
					<span v-if="form.imagemDesktop == null">265px x 282px</span>
				</div>
				<div>
					<label for="input-imagem-desktop" class="upload-btn">Adicionar Imagem</label>
					<transition name="fade">
						<a v-if="form.imagemDesktop" class="remove-image" @click="removerImagem('imagemDesktop')">
							Remover
						</a>
					</transition>
				</div>
			</div>

			<div class="formulario-item container-upload">
				<label class="formulario-item-label">Imagem Mobile</label>
				<input id="input-imagem-mobile" type="file" @change="atualizarImagem('imagemMobile', $event)" />
				<div class="upload-div" :style="imagemBgMobile" :class="{ active: form.imagemMobile }">
					<span v-if="form.imagemMobile == null">265px x 282px</span>
				</div>
				<div>
					<label for="input-imagem-mobile" class="upload-btn">Adicionar Imagem</label>
					<transition name="fade">
						<a v-if="form.imagemMobile" class="remove-image" @click="removerImagem('imagemMobile')">
							Remover
						</a>
					</transition>
				</div>
			</div>
		</div>
		<div class="container-btns">
			<button type="button" class="btn btn-cancelar" @click="voltar">
				Voltar
			</button>
			<button type="submit" class="btn btn-laranja">Salvar</button>
		</div>
	</form>
</template>

<script>
import Box from "@/components/FiltroBox/Box.vue";
import Datepicker from "vuejs-datepicker";
import { ptBR } from "vuejs-datepicker/dist/locale";
import Multiselect from "vue-multiselect";
import { required, minLength, url } from "vuelidate/lib/validators";
import {
	listarTipoTreinamento,
	cadastrarTreinamento,
	atualizarTreinamento,
	detalhesBannerTreinamento,
} from "@/services/banner-treinamento";
import {
	listarTipoAutorizada,
	listarAssistencias,
	listarComboGrupo,
	listarAutorizadaGrupo
} from "@/services/autorizadas";
import { listarCargos } from "@/services/espelho";
import { objects, functions, masks } from "@/assets/js";
import { opcoes } from "@/utils/json";

export default {
	name: "FormularioBannerTreinamento",
	props: ["editar"],
	components: {
		Multiselect,
		Datepicker,
		Box,
	},
	data() {
		return {
			bannerTreinamento: {},
			opcoesAtivo: opcoes.critico,
			showErrors: false,
			objReferencia: {},
			ptBR: ptBR,
			imagemDesktop: null,
			imagemMobile: null,
			tipoTreinamento: [],
			tipo: [],
			assistencias: [],
			franquias: [],
			cargos: [],
			cargosFiltrado: [],
			listaIdsAutorizadas: [],
			filtrosLista: {
				assistencias: [],
				franquias: [],
				regioes: [],
			},
			filtros: [
				{
					assistencias: [],
					franquias: [],
					regioes: [],
					grupo: []
				},
			],
			form: {
				ativo: { id: 1, descricao: "Sim", value: 1 },
				dataFim: "",
				dataInicio: "",
				descricao: "",
				imagemDesktop: null,
				imagemMobile: null,
				link: "",
				nomeTreinamento: "",
				tipoTreinamento: [],
				autorizada: [{ id: 1, descricao: "Assistência" }],
				idAutorizadas: [],
				grupoAutorizadas: [],
			},
			idsCargos: [],
			itemTodos: { id: 0, descricao: "Todos" },
			dadosEditar: {},
			tiposTreinamentosWatch: [],
			comboGrupo: [],
		};
	},
	validations: {
		form: {
			autorizada: { required },
			nomeTreinamento: { required, minLength: minLength(5) },
			link: { required, url },
			ativo: { required },
			tipoTreinamento: { required },
		},
		idsCargos: { required },
	},
	computed: {
		imagemBgDesktop() {
			return `background-image: url(${this.imagemDesktop}); background-position: center; background-size: contain; background-repeat: no-repeat;`;
		},

		imagemBgMobile() {
			return `background-image: url(${this.imagemMobile}); background-position: center; background-size: contain; background-repeat: no-repeat;`;
		},
	},
	mounted() {
		this.popularSelects();
	},
	watch: {
		"form.tipoTreinamento"() {
			this.filtraCargos();
		}
	},
	methods: {
		filtraCargos() {
			if(this.form.tipoTreinamento.id == 1)
			this.idsCargos = this.cargos.filter(item => item.id == 1 || item.id == 4 || item.id == 7);
			if(this.form.tipoTreinamento.id == 2)
			this.idsCargos = this.cargos.filter(item => item.id == 1 || item.id == 4);
			if(this.form.tipoTreinamento.id == 5)
			this.idsCargos = this.cargos.filter(item => item.id == 7);
			if(this.form.tipoTreinamento.id == 3)
			this.idsCargos = this.cargos;
		},
		frasePlaceholder() {
			return this.listaIdsAutorizadas.length > 0
				? `${this.listaIdsAutorizadas.length} ${this.form.autorizada.descricao}(s) selecionada(s)`
				: "Selecione";
		},
		assistenciaSelecionado(segmentacao) {
			if (segmentacao == "assistencia") {
				if (this.filtros.assistencias.descricao == "Todos") {
					this.filtrosLista.assistencias = [];
					this.filtrosLista.assistencias.push(this.filtros.assistencias);
				} else {
					this.filtrosLista.assistencias = this.filtrosLista.assistencias.filter((item) => item.descricao != "Todos");
					this.filtrosLista.assistencias.push(this.filtros.assistencias);
				}
			}

			if (segmentacao == "franquia") {
				if (this.filtros.franquias.descricao == "Todos") {
					this.filtrosLista.franquias = [];
					this.filtrosLista.franquias.push(this.filtros.franquias);
				} else {
					this.filtrosLista.franquias = this.filtrosLista.franquias.filter((item) => item.descricao != "Todos");
					this.filtrosLista.franquias.push(this.filtros.franquias);
				}
			}
		},
		limpaFiltros() {
			this.filtrosLista.assistencias = [];
			this.filtrosLista.franquias = [];
			this.filtros.assistencias = [];
			this.filtros.franquias = [];
			this.filtros.grupo = [];
		},
		popularSelects() {
			listarComboGrupo().then((resp) => {
				this.comboGrupo = resp.data;
			}),
				listarCargos().then((resp) => {
					this.cargos = resp.data;
				}),
				listarAssistencias(1).then((resp) => {
					this.assistencias = resp.data;
					this.assistencias.unshift(this.itemTodos);
				}),
				listarAssistencias(2).then((resp) => {
					this.franquias = resp.data;
					this.franquias.unshift(this.itemTodos);
				}),
				listarTipoAutorizada().then((resp) => {
					this.tipo = resp.data.filter((item) => item.id != 2);
					this.form.autorizada = this.tipo[0];
				}),
				listarTipoTreinamento().then((resp) => {
					this.tipoTreinamento = resp.data;
				});

			setTimeout(() => {
				this.popularDados();
			}, 300);
		},
		listaAutorizadaGrupo() {
			let idGrupo = this.form.grupoAutorizadas.id;
			listarAutorizadaGrupo(idGrupo).then((resp) => {
				if (idGrupo == 1 || idGrupo == 2) {
					this.filtrosLista.assistencias = resp.data;
				}

				if (idGrupo == 3) {
					this.filtrosLista.franquias = resp.data;
				}
			})
		},
		popularObjReferencia() {
			this.objReferencia = {
				nomeTreinamento: this.form.nomeTreinamento,
				dataInicio: masks.formatarData(this.form.dataInicio, "YYYY-MM-DD"),
				dataFim: masks.formatarData(this.form.dataFim, "YYYY-MM-DD"),
				link: this.form.link,
				ativo: this.form.ativo.value,
				idTipoAutorizada: this.form.autorizada.id,
				imagemDesktop: this.form.imagemDesktop,
				imagemMobile: this.form.imagemMobile,
				idTipoTreinamento: this.form.tipoTreinamento.id,
			};
		},
		popularDados() {
			if (this.editar) {
				let id = this.$route.params.id;

				detalhesBannerTreinamento(id).then((resp) => {
					// const infoBannerTreinamento = JSON.parse(JSON.stringify(resp.data));
					// this.bannerTreinamento = infoBannerTreinamento;
					const editar = resp.data;

					this.form = {
						nomeTreinamento: editar.nomeTreinamento,
						dataInicio: editar.dataInicio,
						dataFim: editar.dataFim,
						link: editar.link,
						ativo: functions.verificarItemArray(
							editar.ativo,
							this.opcoesAtivo,
							"value"
						),
						imagemDesktop: editar.imagemDesktop,
						imagemMobile: editar.imagemMobile,
						tipoTreinamento: functions.verificarItemArray(
							editar.tipoTreinamento,
							this.tipoTreinamento,
							"descricao"
						),
					};
					this.form.autorizada = this.tipo.find(
						(item) => item.descricao == editar.tipoSegmentacao
					);

					editar.autorizadas.map((el) => {
						let res = this.assistencias.find((item) => item.id == el.id);
						res && this.filtrosLista.assistencias.push(res);
					});

					editar.autorizadas.map((el) => {
						let res = this.franquias.find((item) => item.id == el.id);
						res && this.filtrosLista.franquias.push(res);
					});

					this.imagemDesktop = editar.imagemDesktop;
					this.imagemMobile = editar.imagemMobile;

					editar.cargos.forEach((el) => {
						let cargo = this.cargos.find((item) => item.id == el);
						this.idsCargos.push(cargo);
					});

					this.filtraCargos();
					// this.popularObjReferencia()
				});
			}
		},
		removerImagem(form) {
			this[form] = null;
			this.form[form] = null;
		},
		atualizarImagem(form, $event) {
			if (!$event.target.files[0]) {
				this.form[form] = null;
				this[form] = null;
				return false;
			}
			this.form[form] = $event.target.files[0];
			const reader = new FileReader();
			reader.onload = (bg) => {
				this[form] = bg.target.result;
			};
			reader.readAsDataURL($event.target.files[0]);
		},
		formData() {
			const data = new FormData();
			this.editar && data.append("Id", this.$route.params.id); // Apenas editar
			data.append("ImagemDesktop", this.form.imagemDesktop);
			data.append("ImagemMobile", this.form.imagemMobile);
			data.append("Link", this.form.link);
			data.append("Descricao", null);
			data.append(
				"DataInicio",
				masks.formatarData(this.form.dataInicio, "YYYY-MM-DD")
			);
			data.append(
				"DataFim",
				masks.formatarData(this.form.dataFim, "YYYY-MM-DD")
			);
			// data.append("idTipoAutorizada", this.form.autorizada && this.form.autorizada.id ? this.form.autorizada.id : 0);
			data.append(
				"IdTipoTreinamento",
				this.form.tipoTreinamento && this.form.tipoTreinamento.id
					? this.form.tipoTreinamento.id
					: 0
			);
			data.append("NomeTreinamento", this.form.nomeTreinamento);
			data.append("ativo", this.form.ativo.value);
			data.append(
				"IdSegmentacao",
				this.form.autorizada && this.form.autorizada.id
					? this.form.autorizada.id
					: 0
			);
			// this.listaIdsAutorizadas.map((res) => {
			// 	data.append("IdsAutorizadas", res.id);
			// });


			if (this.filtrosLista.assistencias.length > 0) {
				if (this.filtrosLista.assistencias[0].id == 0) {
					this.assistencias.map((res) => {
						res.id != 0 && data.append("IdsAutorizadas", res.id);
					});
				} else {
					this.filtrosLista.assistencias.map((res) => {
						data.append("IdsAutorizadas", res.id);
					});
				}
			}

			if (this.filtrosLista.franquias.length > 0) {
				if (this.filtrosLista.franquias[0].id == 0) {
					this.franquias.map((res) => {
						res.id != 0 && data.append("IdsAutorizadas", res.id);
					});
				} else {
					this.filtrosLista.franquias.map((res) => {
						data.append("IdsAutorizadas", res.id);
					});
				}
			}

			this.idsCargos.map((res) => {
				data.append("IdsCargos", res.id);
			});

			return data;
		},
		salvar() {
			if (this.$v.$invalid) {
				return (this.showErrors = true);
			} else if (
				objects.comparacaoObjForm(this.objReferencia, this.formData()) &&
				this.editar
			) {
				this.$store.commit("SET_MODAL", {
					ativado: true,
					mensagem: "Não houve nenhuma mudança nos dados",
					tipo: "erro",
				});
			} else {
				// Modal
				const config = {
					ativado: true,
					tipo: "confirmacao",
					mensagem: `Tem certeza que deseja ${this.editar ? "alterar" : "cadastrar"
						} este treinamento ?`,
					func: this.salvarTreinamentos,
				};
				this.$store.commit("SET_MODAL_MENSAGEM", config);
			}
		},
		salvarTreinamentos() {
			this.editar
				? atualizarTreinamento(this.formData()).then(() => {
					this.popularObjReferencia();
				})
				: cadastrarTreinamento(this.formData()).then(() => {
					this.voltar();
				});
		},
		voltar() {
			this.$router.push("/cadastro-banner-treinamentos/listar");
		},
	},
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css" />