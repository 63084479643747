const obj = {

    objetosIguais(obj1, obj2) { // O obj1 sempre será a variável de comparação
        // console.log('obj1',  obj1, 'obj2',  obj2)
        if (Object.keys(obj1).length) {

            for (const key in obj1) {
                const chaveAnterior = obj1[key];
                const chaveAtual = obj2[key];

                //    console.log(key, chaveAnterior, chaveAtual)

                if (!(chaveAtual === chaveAnterior)) return false
            }
            return true
        }
        return false
    },

    comparacaoObjForm(obj, form) {
        //    console.log('obj',  obj, 'form',  form)
        if (Object.keys(obj).length) {
            for (const key in obj) {
                const chaveAnterior = obj[key];
                let chaveAtualForm = form.get([key]);

                const transformaStringBoolean = (string) => string == 'true' ? true : false
                if (chaveAtualForm == 'true' || chaveAtualForm == 'false') chaveAtualForm = transformaStringBoolean(chaveAtualForm)
                if (chaveAtualForm == 'null') chaveAtualForm = null

                   console.log(key, chaveAnterior, chaveAtualForm, chaveAtualForm == chaveAnterior)

                if (!(chaveAtualForm == chaveAnterior)) return false
            }
            return true
        }
        return false
    }

}

export default obj
