import store from '@/store'
import functions from './functions'

const requests = {

  // obj é o obj que será preenchido 
  // func é a função que será executada caso o cep for encontrado
  buscaCep(cep, obj, func) {
    // console.log(cep, obj)
    cep = cep.replace(/\D/g, "");
    const getCep = `https://viacep.com.br/ws/${cep}/json/`;
    const options = {
      methods: "GET",
      mode: "cors",
      cache: "default",
    };

    if (cep.length === 8) {
      store.commit("SET_ADICIONAR_LOADING");

      fetch(getCep, options).then((resp) => {
        resp.json().then((data) => {
          if (data.erro) {
            obj.endereco = "";
            obj.bairro = "";
            obj.cidade = "";
            obj.uf = "";
            return store.commit("SET_MODAL", {
              ativado: true,
              mensagem: 'CEP não encontrado',
              tipo: "erro",
            });
          }
          obj.endereco = data.logradouro;
          obj.bairro = data.bairro;
          obj.cidade = data.localidade;
          obj.uf = functions.verificarUf(data.uf);
          func && func() // Roda a função caso haja uma
        });
      }).finally(() => {
        store.commit("SET_REMOVER_LOADING")
      });
    } else if (cep.length > 0 && cep.length < 8) {
      store.commit("SET_MODAL", {
        ativado: true,
        mensagem: 'CEP inválido',
        tipo: "erro",
      });
    }
  }

}

export default requests