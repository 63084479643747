import estados from "@/utils/json/estados-brasileiros"

const functions = {

    // Verificação de status apenas há dois resultados Ativo|Inativo
    verificarStatus(boolean) {
        if (boolean !== undefined) {
            return boolean ? { id: 1, descricao: "Ativo", value: true } : { id: 2, descricao: "Inativo", value: false };
        }
        console.log(`Erro na funcao verificarStatus: valor retornado ${boolean}`)
    },

    // Verifica a UF passada como parâmetro
    verificarUf(string) {
        const { ufRegiao } = estados

        for (const key in ufRegiao) {
            const estados = ufRegiao[key].estados;
            estados.find(item => item.descricao === string && (string = item))
            if (typeof string === 'object') break;
        }

        return string
    },

    // Verifica se a variável passada como parâmetro é válido
    verificarDadoValido(data) {
        if (data == null || data == undefined || data == "") return false
        return true
    },

    // Retorna o elemento
    verificarItemArray(el, arr, param) {
        el = arr.find(item => {
            return item[param] === el
        })
        return el
    },

    // Retorna Boolean
    verificarSeHaItem(el, arr, param) {
        return arr.some((obj) => obj[param] === el);
    },

    // Retorna array de obj ordenado em ordem crescente
    ordenarObj(array, param) {
        function compare(primeiro, segundo) {
            const primeiroAtt = parseInt(primeiro[param])
            const segundoAtt = parseInt(segundo[param])
            if (primeiroAtt < segundoAtt)
                return -1;
            if (primeiroAtt > segundoAtt)
                return 1;
            return 0;
        }

        return array.sort(compare);
    }
}

export default functions;