<template>
    <div class="box-filtros" v-if="lista.length > 0">
        <div class="box-filtros__header">
            <h2 class="titulo">{{ titulo }}(s)</h2>
            <div class="grp-btns">
                <button type="button" class="btn-pattern btn-pattern_laranja-secundario"
                    @click="$emit('update:lista', ordenarArray(listaComputed, 'codigoSAP'))">
                    Ordenar por cód. SAP
                </button>
                <button type="button" class="btn-pattern btn-pattern_laranja-secundario" @click="limparBox">
                    Limpar {{ titulo }}(s)
                </button>
            </div>
        </div>
        <div class="box-filtros__tabela-informacoes" v-if="lista.length > 0">
            <div class="tabela-linha tabela-linha-informacoes">
                <div v-for="(item, index) in listaComputed" :key="index">
                    <p>{{ item.descricao }}</p>
                    <button type="button" @click="lista.splice(lista.indexOf(item), 1)" class="btn-pattern_laranja-excluir">
                        X
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { functions } from '@/assets/js'

export default {
    name: "FiltroBox",

    props: {
        titulo: {
            required: true,
            type: String
        },
        lista: {
            required: true,
            type: Array
        },
        varSelect: {
            require: false
        }
    },

    computed: {
        listaComputed: {
            get: function () {
                return this.lista;
            },
            set: function (newValue) {
                this.$emit('update:lista', newValue)
            }
        },
        varSelectComputed: {
            get: function () {
                return this.varSelect;
            },
            set: function (newValue) {
                this.$emit('update:varSelect', newValue)
            }
        },
    },

    methods: {
        ordenarArray: functions.ordenarObj,
        limparBox() {
            this.$emit('update:lista', [])
            this.$emit('update:varSelect', [])
        }
    }
}
</script>

<style lang="scss">
.box-filtros {
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
        margin-bottom: 10px;

        @media (max-width: 768px) {
            flex-direction: column;
            gap: 20px;
        }

        .titulo {
            font-size: 18px;
        }

        .grp-btns {
            display: flex;
            gap: 20px;

            @media (max-width:768px) {
                width: 100%;
                justify-content: space-between;
            }
        }
    }

    &__tabela-informacoes {
        margin-left: 10px;
        overflow: auto;
    }
}
</style>